import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.UserController
 */

@Injectable()
@ResourceParams({})
export class UserResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/doLogin',
        endpoint: 'cms'
    })
    private _login: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<boolean>>;
    login(): Promise<boolean> {
        return this._login(null, null, null).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/doLogout',
        endpoint: 'cms'
    })
    private _logout: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<boolean>>;
    logout(): Promise<boolean> {
        return this._logout(null, null, null).toPromise().then((result) => result.value)
    }

}
