import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {HomeBulkData, HomePageConfigData, JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.HomePageController
 */

@Injectable()
@ResourceParams({})
export class HomePageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/home',
        endpoint: 'cms'
    })
    private _getBundlesForHome: IResourceMethodObservableStrict<void, {mobileBanner: boolean}, {}, HomeBulkData>;
    getBundlesForHome(requestParams: {mobileBanner: boolean}): Promise<HomeBulkData> {
        return this._getBundlesForHome(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/pageconfigs/home/header',
        endpoint: 'cms'
    })
    private _getHomePageConfig: IResourceMethodObservableStrict<void, {}, {}, HomePageConfigData>;
    getHomePageConfig(): Promise<HomePageConfigData> {
        return this._getHomePageConfig(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/pageconfigs/home/titletag',
        endpoint: 'cms'
    })
    private _getHomePageTitleTag: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<string>>;
    getHomePageTitleTag(): Promise<string> {
        return this._getHomePageTitleTag(null, null, null).toPromise().then((result) => result.value)
    }

}
