import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {StringData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.GlobalConfigController
 */

@Injectable()
@ResourceParams({})
export class GlobalConfigResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/config/global/css',
        endpoint: 'cms'
    })
    private _updateGlobalCss: IResourceMethodObservableStrict<StringData, {}, {}, void>;
    updateGlobalCss(requestBody: StringData): Promise<void> {
        return this._updateGlobalCss(requestBody, null, null).toPromise()
    }

}
