import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicImageController
 */

@Injectable()
@ResourceParams({})
export class PublicImageResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/images/containers/{id}',
        endpoint: 'cms'
    })
    private _getContainerImage: IResourceMethodObservableStrict<void, {width?: number, height?: number}, {id: number}, Blob>;
    getContainerImage(id: number, requestParams?: {width?: number, height?: number}): Promise<Blob> {
        return this._getContainerImage(null, requestParams, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/upload_image/{id}',
        endpoint: 'cms'
    })
    private _getInlineImage: IResourceMethodObservableStrict<void, {}, {id: number}, Blob>;
    getInlineImage(id: number): Promise<Blob> {
        return this._getInlineImage(null, null, {id: id}).toPromise()
    }

}
