import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SeminarCheckoutGatewayComponent} from './seminar-checkout-gateway/seminar-checkout-gateway.component';
import {RouterModule, Routes} from '@angular/router';
import {ClickOutsideDirective} from './click-outside.directive';
import {SemCheckoutS3Module} from './seminar-checkout-s3/sem-checkout-s3.module';
import {SemCheckoutS4Module} from "./seminar-checkout-s4/sem-checkout-s4.module";


const appRoutes: Routes = [{path: 'angebote/:routingUrl/checkout/seminars/g', component: SeminarCheckoutGatewayComponent}];

@NgModule({
  declarations: [
    SeminarCheckoutGatewayComponent,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    SemCheckoutS3Module,
    SemCheckoutS4Module,
    RouterModule.forChild(appRoutes)
  ]
})
export class SeminarCheckoutModule { }
