import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.public.PublicFilesController
 */

@Injectable()
@ResourceParams({})
export class PublicFilesResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/files/images/{fileId}',
        endpoint: 'cms'
    })
    private _getFileById: IResourceMethodObservableStrict<void, {}, {fileId: number}, Blob>;
    getFileById(fileId: number): Promise<Blob> {
        return this._getFileById(null, null, {fileId: fileId}).toPromise()
    }

}
