import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.StatusController
 */

@Injectable()
@ResourceParams({})
export class StatusResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/status/live',
        endpoint: 'cms'
    })
    private _live: IResourceMethodObservableStrict<void, {}, {}, any>;
    live(): Promise<any> {
        return this._live(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/status/ready',
        endpoint: 'cms'
    })
    private _ready: IResourceMethodObservableStrict<void, {}, {}, any>;
    ready(): Promise<any> {
        return this._ready(null, null, null).toPromise()
    }

}
