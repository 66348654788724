import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnalyticsService} from '../../analytics/analytics.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MediaService} from 'src/app/inno-utils/media.service';
import {GDPRConsent, GDPRConsentService} from '../gdprconsent.service';

@Component({
  selector: 'app-gdprconsent',
  templateUrl: './gdprconsent.component.html',
  styleUrls: ['./gdprconsent.component.scss']
})
export class GDPRConsentComponent implements OnInit {


  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  consentState: GDPRConsent;

  @Input() detailedView = false;

  initialConsent = false;

  form: FormGroup;

  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
    public mediaService: MediaService,
    private fb: FormBuilder,
    private gdprConsentService: GDPRConsentService
  ) {
    this.form = fb.group({
      consent_ga: [true],
      consent_linkedIn: [true],
      consent_facebook: [true],
      consent_pinterest: [true],
      consent_google_ads: [true],
      consent_tiktok: [true],
      consent_google_oc: [true],
      consent_facebook_oc: [true],
      consent_hotjar: [true],
      consent_bing: [true],
      consent_ipApi: [true],
      consent_reddit_ads: [true],
      consent_snapchat_ads: [true]
    });
  }


  ngOnInit() {


    let consentState = this.gdprConsentService.consent;
    this.initialConsent = consentState.initial;

    this.form.patchValue({
        consent_ga: consentState.consent_ga,
        consent_linkedIn: consentState.consent_linkedIn,
        consent_facebook: consentState.consent_facebook,
        consent_pinterest: consentState.consent_pinterest,
        consent_google_ads: consentState.consent_google_ads,
        consent_tiktok: consentState.consent_tiktok,
        consent_google_oc: consentState.consent_google_oc,
        consent_facebook_oc: consentState.consent_facebook_oc,
        consent_hotjar: consentState.consent_hotjar,
        consent_bing: consentState.consent_bing,
        consent_ipApi: consentState.consent_ipApi,
        consent_reddit_ads: consentState.consent_reddit_ads,
        consent_snapchat_ads: consentState.consent_snapchat_ads
      }
    );
  }

  selectAll() {
    this.form.patchValue({
        consent_ga: true,
        consent_linkedIn: true,
        consent_facebook: true,
        consent_pinterest: true,
        consent_google_ads: true,
        consent_tiktok: true,
        consent_google_oc: true,
        consent_facebook_oc: true,
        consent_hotjar: true,
        consent_bing: true,
        consent_reddit_ads: true,
        consent_snapchat_ads: true,
        consent_ipApi: true
      }
    );
  }

  submitAll() {
    this.selectAll();
    this.submit();
  }

  submit() {
    this.gdprConsentService.updateGDPRConsent({
      initial: false,
      consent_ga: this.form.controls.consent_ga.value,
      consent_linkedIn: this.form.controls.consent_linkedIn.value,
      consent_facebook: this.form.controls.consent_facebook.value,
      consent_pinterest: this.form.controls.consent_pinterest.value,
      consent_google_ads: this.form.controls.consent_google_ads.value,
      consent_tiktok: this.form.controls.consent_tiktok.value,
      consent_google_oc: this.form.controls.consent_google_oc.value,
      consent_facebook_oc: this.form.controls.consent_facebook_oc.value,
      consent_hotjar: this.form.controls.consent_hotjar.value,
      consent_bing: this.form.controls.consent_bing.value,
      consent_ipApi: this.form.controls.consent_ipApi.value,
      consent_reddit_ads: this.form.controls.consent_reddit_ads.value,
      consent_snapchat_ads: this.form.controls.consent_snapchat_ads.value
    });
    this.analyticsService.pushGoogleConsents(this.form.controls.consent_ga.value, this.form.controls.consent_google_ads.value, 'update');

    this.finish();
  }


  finish() {
    this.close.next();
    if (this.initialConsent) {
      this.analyticsService.reportPageView();
    }
  }

  routePrivacy() {
    this.router.navigateByUrl('/datenschutz');
    this.close.next();
  }

}
