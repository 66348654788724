import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {GeneralConditionsOfParticipationTemplateData, JsonWrappedValue} from "../data"

/**
 * This resource class was generated from Controller de.syntacton.cronos.controller.externalv2.LegalAPIControllerV2
 */

@Injectable()
@ResourceParams({})
export class LegalAPIResourceV2 extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/{formatId}/conditionsOfParticipation',
        endpoint: 'cronos'
    })
    private _getCurrentConditionsOfParticipation: IResourceMethodObservableStrict<void, {}, {formatId: number}, JsonWrappedValue<string>>;
    getCurrentConditionsOfParticipation(formatId: number): Promise<JsonWrappedValue<string>> {
        return this._getCurrentConditionsOfParticipation(null, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/general/conditionsOfParticipation',
        endpoint: 'cronos'
    })
    private _getCurrentGeneralConditionsOfParticipation: IResourceMethodObservableStrict<void, {}, {}, GeneralConditionsOfParticipationTemplateData>;
    getCurrentGeneralConditionsOfParticipation(): Promise<GeneralConditionsOfParticipationTemplateData> {
        return this._getCurrentGeneralConditionsOfParticipation(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/privacy',
        endpoint: 'cronos'
    })
    private _getCurrentPrivacyText: IResourceMethodObservableStrict<void, {}, {}, JsonWrappedValue<string>>;
    getCurrentPrivacyText(): Promise<JsonWrappedValue<string>> {
        return this._getCurrentPrivacyText(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/{formatId}/privacy',
        endpoint: 'cronos'
    })
    private _getCurrentPrivacyTextForFormat: IResourceMethodObservableStrict<void, {}, {formatId: number}, JsonWrappedValue<string>>;
    getCurrentPrivacyTextForFormat(formatId: number): Promise<JsonWrappedValue<string>> {
        return this._getCurrentPrivacyTextForFormat(null, null, {formatId: formatId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/apiv2/legal/promotion/{promotionId}/privacy',
        endpoint: 'cronos'
    })
    private _getCurrentPrivacyTextForPromotion: IResourceMethodObservableStrict<void, {}, {promotionId: number}, JsonWrappedValue<string>>;
    getCurrentPrivacyTextForPromotion(promotionId: number): Promise<JsonWrappedValue<string>> {
        return this._getCurrentPrivacyTextForPromotion(null, null, {promotionId: promotionId}).toPromise()
    }

}
