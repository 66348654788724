import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {
  BlogCategoryData,
  BooleanData,
  CreateBlogCategoryData,
  CreateBlogPostData,
  InternalBlogPostData,
  JsonWrappedValue,
  PagedData
} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.InternalBlogController
 */

@Injectable()
@ResourceParams({})
export class InternalBlogResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/blog/posts',
        endpoint: 'cms'
    })
    private _createBlogPost: IResourceMethodObservableStrict<CreateBlogPostData, {}, {}, JsonWrappedValue<number>>;
    createBlogPost(requestBody: CreateBlogPostData): Promise<JsonWrappedValue<number>> {
        return this._createBlogPost(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/internal/blog/categories',
        endpoint: 'cms'
    })
    private _createCategory: IResourceMethodObservableStrict<CreateBlogCategoryData, {}, {}, BlogCategoryData>;
    createCategory(requestBody: CreateBlogCategoryData): Promise<BlogCategoryData> {
        return this._createCategory(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/blog/posts/{id}',
        endpoint: 'cms'
    })
    private _deleteBlogPost: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deleteBlogPost(id: number): Promise<void> {
        return this._deleteBlogPost(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/internal/blog/categories/{id}',
        endpoint: 'cms'
    })
    private _deleteCategory: IResourceMethodObservableStrict<void, {}, {id: number}, void>;
    deleteCategory(id: number): Promise<void> {
        return this._deleteCategory(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/blog/posts/{postId}',
        endpoint: 'cms'
    })
    private _getBlogPost: IResourceMethodObservableStrict<void, {}, {postId: number}, InternalBlogPostData>;
    getBlogPost(postId: number): Promise<InternalBlogPostData> {
        return this._getBlogPost(null, null, {postId: postId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/internal/blog/posts',
        endpoint: 'cms'
    })
    private _getBlogPosts: IResourceMethodObservableStrict<void, {q?: string, categoryId?: number, page?: number, pageSize?: number}, {}, PagedData<InternalBlogPostData>>;
    getBlogPosts(requestParams?: {q?: string, categoryId?: number, page?: number, pageSize?: number}): Promise<PagedData<InternalBlogPostData>> {
        return this._getBlogPosts(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/blog/posts/{id}',
        endpoint: 'cms'
    })
    private _updateBlogPost: IResourceMethodObservableStrict<InternalBlogPostData, {}, {id: number}, JsonWrappedValue<boolean>>;
    updateBlogPost(requestBody: InternalBlogPostData, id: number): Promise<boolean> {
        return this._updateBlogPost(requestBody, null, {id: id}).toPromise().then((result) => result.value)
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/blog/posts/{id}/highlight',
        endpoint: 'cms'
    })
    private _updateBlogPostHighlightState: IResourceMethodObservableStrict<BooleanData, {}, {id: number}, void>;
    updateBlogPostHighlightState(requestBody: BooleanData, id: number): Promise<void> {
        return this._updateBlogPostHighlightState(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/blog/posts/{id}/visible',
        endpoint: 'cms'
    })
    private _updateBlogPostVisibility: IResourceMethodObservableStrict<BooleanData, {}, {id: number}, void>;
    updateBlogPostVisibility(requestBody: BooleanData, id: number): Promise<void> {
        return this._updateBlogPostVisibility(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/internal/blog/categories/{categoryId}',
        endpoint: 'cms'
    })
    private _updateCateogry: IResourceMethodObservableStrict<BlogCategoryData, {}, {categoryId: number}, BlogCategoryData>;
    updateCateogry(requestBody: BlogCategoryData, categoryId: number): Promise<BlogCategoryData> {
        return this._updateCateogry(requestBody, null, {categoryId: categoryId}).toPromise()
    }

}
