import {Injectable} from "@angular/core";
import {
  IResourceMethodObservableStrict,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ApiResource} from "../../../api-resource";
import {PagedData, SyncedFormatData} from "../data"

/**
 * This resource class was generated from Controller net.innospire.hidcms.controllers.secured.SyncedFormatController
 */

@Injectable()
@ResourceParams({})
export class SyncedFormatResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/syncedFormats',
        endpoint: 'cms'
    })
    private _getSyncedFormats: IResourceMethodObservableStrict<void, {q: string, page?: number, pageSize?: number}, {}, PagedData<SyncedFormatData>>;
    getSyncedFormats(requestParams: {q: string, page?: number, pageSize?: number}): Promise<PagedData<SyncedFormatData>> {
        return this._getSyncedFormats(null, requestParams, null).toPromise()
    }

}
